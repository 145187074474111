import React from "react"
import { Helmet } from "react-helmet"
import LayoutDark from "./shared/layout/layout-dark"
import "../../static/assets/scss/pages/page.scss"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
import { navigate } from "gatsby-link"

const routes = require("../types/routes")

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }
  onBackToHome() {
    navigate(routes.routes.getStarted)
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Pricing</title>
          <meta name="title" content="Contingent: Plans" />
          <meta
            name="description"
            content="Product and pricing offerings to meet the unique KYS risk management needs of your enterprise. "
          />
          <link rel="canonical" href={`${process.env.BASE_URL}/s/`} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.BASE_URL}/s/`} />
          <meta property="og:title" content="Contingent: Plans" />
          <meta
            property="og:description"
            content="Product and pricing offerings to meet the unique KYS risk management needs of your enterprise. "
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`${process.env.BASE_URL}/s/`} />
          <meta property="twitter:title" content="Contingent: Plans" />
          <meta
            property="twitter:description"
            content="Product and pricing offerings to meet the unique KYS risk management needs of your enterprise. "
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column centered"}>
                  <div className={"content"}>
                    <h1 className={"title"}>Pricing</h1>
                    <p className={"description"}>
                      Whether you’re at a Fortune 500 Company or a startup,
                      Contingent can help you source better data on the
                      businesses and suppliers you work with.
                    </p>
                  </div>
                  <div className={"price-list"}>
                    <div className={"row"}>
                      <div className={"column"}>
                        <div className={"price-card"}>
                          <div className={"header"}>Pro </div>
                          <div className={"items"}>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Strategic suppliers</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Reference data</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Ownership & Officers</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Screening & Sanctions
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Financial Fragility</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Evidence</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Email support</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-546 -773)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(546 773)"
                                      fill="#f84343"
                                      opacity="0.113"
                                    />
                                    <g transform="translate(-39 644)">
                                      <line
                                        x2="7"
                                        y2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y1="7"
                                        x2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Dedicated Account Manager
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-546 -773)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(546 773)"
                                      fill="#f84343"
                                      opacity="0.113"
                                    />
                                    <g transform="translate(-39 644)">
                                      <line
                                        x2="7"
                                        y2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y1="7"
                                        x2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Bulk import</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-546 -773)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(546 773)"
                                      fill="#f84343"
                                      opacity="0.113"
                                    />
                                    <g transform="translate(-39 644)">
                                      <line
                                        x2="7"
                                        y2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y1="7"
                                        x2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Monitoring and alerting
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-546 -773)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(546 773)"
                                      fill="#f84343"
                                      opacity="0.113"
                                    />
                                    <g transform="translate(-39 644)">
                                      <line
                                        x2="7"
                                        y2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y1="7"
                                        x2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Integrations</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-546 -773)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(546 773)"
                                      fill="#f84343"
                                      opacity="0.113"
                                    />
                                    <g transform="translate(-39 644)">
                                      <line
                                        x2="7"
                                        y2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y1="7"
                                        x2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Teams</div>
                            </div>
                            <div className={"item"}>
                              <button
                                type={"button"}
                                className={"button"}
                                onClick={e => this.onBackToHome(e)}
                              >
                                REQUEST A DEMO
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"column"}>
                        <div className={"price-card"}>
                          <div className={"header"}>Business </div>
                          <div className={"items"}>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Strategic suppliers</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Reference data</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Ownership & Officers</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Screening & Sanctions
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Financial Fragility</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Evidence</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Email support</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Dedicated Account Manager
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Bulk import</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Monitoring and alerting
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Integrations</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-546 -773)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(546 773)"
                                      fill="#f84343"
                                      opacity="0.113"
                                    />
                                    <g transform="translate(-39 644)">
                                      <line
                                        x2="7"
                                        y2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y1="7"
                                        x2="7"
                                        transform="translate(591.5 135.5)"
                                        fill="none"
                                        stroke="#f84343"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Teams</div>
                            </div>
                            <div className={"item"}>
                              <button
                                type={"button"}
                                className={"button"}
                                onClick={e => this.onBackToHome(e)}
                              >
                                REQUEST A DEMO
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"column"}>
                        <div className={"price-card dark"}>
                          <div className={"header"}>Enterprise </div>
                          <div className={"items"}>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Strategic suppliers</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Reference data</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Ownership & Officers</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Screening & Sanctions
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Financial Fragility</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Evidence</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Email support</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Dedicated Account Manager
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Bulk import</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>
                                Monitoring and alerting
                              </div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Integrations</div>
                            </div>
                            <div className={"item"}>
                              <div className={"icon"}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <g transform="translate(-204 -724)">
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      transform="translate(204 724)"
                                      fill="#23afff"
                                      opacity="0.113"
                                    />
                                    <path
                                      d="M4.135,43.045,0,39.236l1.439-1.562,2.478,2.283L8.8,33.984l1.644,1.345Z"
                                      transform="translate(208.983 695.265)"
                                      fill="#0ba4e0"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className={"name"}>Teams</div>
                            </div>
                            <div className={"item"}>
                              <button
                                type={"button"}
                                className={"button"}
                                onClick={e => this.onBackToHome(e)}
                              >
                                REQUEST A DEMO
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"section-social-proof"}>
            <div className={"text"}>
              Trusted by organizations of all shapes and sizes
            </div>
            <div className={"logos"}>
              <img
                src={"/assets/images/logos/ukgovblack.svg"}
                className={"logo"}
              />
              <img
                src={"/assets/images/logos/monzo-black.svg"}
                className={"logo"}
              />
              <img
                src={"/assets/images/logos/healthcare.svg"}
                className={"logo"}
              />
              <img src={"/assets/images/logos/vyne.svg"} className={"logo"} />
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
